/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		common: {
			init: function () {
				// JavaScript to be fired on all pages
				var galleryWrapper = document.querySelector(
					".gallery__list.masonry-active"
				);
				if (galleryWrapper) {
					var msnry = new Masonry(galleryWrapper, {
						// options
						itemSelector: ".gallery__item",
					});
				}

				var MqL = 800;
				//open search form
				$(".search_form_trigger").on("click", function (event) {
					event.preventDefault();
					toggleSearch();
				});

				$(document).keyup(function (e) {
					if (e.keyCode === 27) {
						toggleSearch("close");
					}
				});

				("use strict");
				if ($(window).width() < MqL) {
					var menu = $("#menu-hauptmenue.nav");

					// Return early if there's no menu
					if (!menu) {
						return;
					}

					var dropdownLi = menu.find(".menu-item-has-children");
					var dropdownLink = menu.find(".menu-item-has-children > a");

					// Return early if there's no dropdown
					if (!dropdownLi) {
						return;
					}

					// Set attr to all dropdowns by default
					dropdownLi.attr("data-dropdown", "closed");

					// Use modernizr to decide on touch/hover behaviour
					if (Modernizr.touchevents) {
						dropdownLink.click(function (event) {
							if ($(this).parent().attr("data-dropdown") != "opened") {
								// Set 'data-dropdown' attr to 'opened'
								$(this).parent().attr("data-dropdown", "opened");

								// Set 'data-dropdown' attr on other submeus to 'closed'
								$(this).parent().siblings().attr("data-dropdown", "closed");

								// Set 'data-dropdown' attr on other nested subenus to 'closed'
								$(this)
									.parent()
									.siblings()
									.find(".menu-item-has-children")
									.attr("data-dropdown", "closed");

								// Prevent default click
								return false;
								// event.preventDefault();
								// event.stopImmediatePropagation();
							}
						});

						// Close all menus on scroll
						$(".site-wrapper").scroll(function () {
							dropdownLi.attr("data-dropdown", "closed");
						});

						// Close all dropdowns when clicked anywhere
						$(document).click(function () {
							dropdownLi.attr("data-dropdown", "closed");
						});
					} else {
						// Now hover behaviour

						dropdownLi.each(function () {
							$(this).mouseenter(function () {
								$(this).attr("data-dropdown", "opened");
							});

							$(this).mouseleave(function () {
								$(this).attr("data-dropdown", "closed");
							});
						});

						// Prevent default click if there's just a `#` instead of a link
						dropdownLink.on("click", function () {
							if (this.href.indexOf("#") != -1) {
								return false;
								// event.preventDefault();
								// event.stopImmediatePropagation();
							}
						});
					}
				}

				// open/close lateral navigation
				var isLateralNavAnimating = false;
				$(".menu-drawer").on("click", function (event) {
					event.preventDefault();
					//stop if nav animation is running
					if (!isLateralNavAnimating) {
						if ($(this).parents(".csstransitions").length > 0) {
							isLateralNavAnimating = true;
						}
						$("body").toggleClass("navigation-is-open");
						$(".nav-wrapper").one(
							"webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
							function () {
								//animation is over
								isLateralNavAnimating = false;
							}
						);
					}
				});

				$(".map__group")
					.children()
					.each(function (e) {
						$(this).on("click", function (event) {
							var districtID = $(this).attr("id");
							$(".map__contacts")
								.not(".map__contacts--" + districtID)
								.removeClass("is-visible");
							$(".map__contacts--" + districtID).toggleClass("is-visible");

							if ($(".map__contacts--" + districtID).hasClass("is-visible")) {
								$(".map__intro").hide();
							} else {
								$(".map__intro").show();
							}
						});
					});

				$(document).mouseup(function (e) {
					var container = $(".map__contacts.is-visible");
					// if the target of the click isn't the container nor a descendant of the container
					if (!container.is(e.target) && container.has(e.target).length === 0) {
						container.removeClass("is-visible");
						$(".map__intro").show();
					}
				});

				$districtName = $(".district-name");

				$(".map__district").hover(
					function () {
						$districtName.addClass("active");
						$districtName.html($(this).attr("data-district-name"));
					},
					function () {
						$districtName.removeClass("active");
					}
				);

				$(".map__svg").on("mousemove", function (e) {
					$districtName.css({
						left: e.pageX - $(".map__svg").offset().left,
						top: e.pageY - 60 - $(".map__svg").offset().top,
					});
				});

				function resizeDiv() {
					var vpw = $(window).width();
					var vph = $(window).height();
					if (vph === undefined) {
						vph = window.innerHeight;
					}
					$("#home-slider .slide").css({
						height: vph - 200 + "px",
					});
				}

				// wait for the page to load
				$(window).on("load", function () {
					var searchFormWidth =
						$(".nav-primary").outerWidth() -
						$(".nav.search_form__container").outerWidth();

					if ($(window).width() > MqL) {
						$(".search_form").css("width", searchFormWidth + "px");
					}
				});

				function toggleSearch(type) {
					if (type === "close") {
						//close search
						$(".search_form").removeClass("is-visible");
						$(".search_form_trigger").removeClass("search-is-visible");
						// $('.overlay').removeClass('search-is-visible');
					} else {
						//toggle search visibility
						$(".search_form").toggleClass("is-visible");
						$(".search_form_trigger").toggleClass("search-is-visible");
						// $('.overlay').toggleClass('search-is-visible');

						if (
							$(window).width() > MqL &&
							$(".search_form").hasClass("is-visible")
						) {
							$(".search_form").find('input[type="search"]').focus();
						}

						if ($(".search_form").hasClass("is-visible")) {
							// $('.overlay').addClass('is-visible');
						} else {
							// $('.overlay').removeClass('is-visible');
						}
					}
				}

				// Slider Projects
				$("#home-slider").lightSlider({
					item: 1,
					autoWidth: false,
					slideMove: 1, // slidemove will be 1 if loop is true
					slideMargin: 0,

					addClass: "",
					mode: "fade",
					useCSS: true,
					cssEasing: "ease", //'cubic-bezier(0.25, 0, 0.25, 1)',//
					easing: "linear", //'for jquery animation',////

					speed: 1000, //ms'
					auto: true,
					pauseOnHover: true,
					loop: true,
					slideEndAnimation: true,
					pause: 5000,

					keyPress: false,
					controls: true,
					// prevHtml: "",
					// nextHtml: "",

					rtl: false,
					adaptiveHeight: false,

					vertical: false,
					verticalHeight: 500,
					vThumbWidth: 100,

					thumbItem: 10,
					pager: false,
					gallery: false,
					galleryMargin: 5,
					thumbMargin: 5,
					currentPagerPosition: "middle",

					enableTouch: true,
					enableDrag: false,
					freeMove: true,
					swipeThreshold: 40,

					responsive: [],

					onBeforeStart: function (el) {},
					onSliderLoad: function (el) {
						if ($(window).width() > 960) {
							resizeDiv();
						}
					},
					onBeforeSlide: function (el) {},
					onAfterSlide: function (el) {},
					onBeforeNextSlide: function (el) {},
					onBeforePrevSlide: function (el) {},
				});

				if ($(window).width() > 960) {
					resizeDiv();
				}
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			},
		},
		// Home page
		home: {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			},
		},
		// About us page, note the change from about-us to about_us.
		about_us: {
			init: function () {
				// JavaScript to be fired on the about us page
			},
		},
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = funcname === undefined ? "init" : funcname;
			fire = func !== "";
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === "function";

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire("common");

			// Fire page-specific init JS, and then finalize JS
			$.each(
				document.body.className.replace(/-/g, "_").split(/\s+/),
				function (i, classnm) {
					UTIL.fire(classnm);
					UTIL.fire(classnm, "finalize");
				}
			);

			// Fire common finalize JS
			UTIL.fire("common", "finalize");
		},
	};
	// Load Events
	$(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
